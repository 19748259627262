/* You can add global styles to this file, and also import other style files */
body {
  padding-top: 0 !important;
}

pre {
  color: black;
  background-color: white;
  margin:0!important;
}
